import { configureStore } from '@reduxjs/toolkit';
import nftSlice from './nfts/nft-slice';
import filtersSlice from './filters/filters-slice';
import loaderSlice from './loader/loader-slice';
import selectedArtworkSlice from './selectedArtwork/selectedArtwork-slice';

const store = configureStore({
  reducer: {
    nfts: nftSlice.reducer,
    filters: filtersSlice.reducer,
    loader: loaderSlice.reducer,
    selectedArtwork: selectedArtworkSlice.reducer,
  },
});

export default store;

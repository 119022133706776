import { getUserInfo } from '../../helper/localStorageFun/localStorageFun';
import { getGuestUserToken } from '../../helper/envVariables/envVariables';
import tokenTypes from '../../helper/tokenTypes/tokenType';
import cookies from 'js-cookie';

export const getToken = () => {
  const user = getUserInfo();
  const token = tokenTypes.bearer + (user?.token?.access_token || getGuestUserToken());
  return token;
};

export const getLocale = () => {
  return cookies.get('i18next');
};

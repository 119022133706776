import marketPlaceInstance from '../marketPlaceInstance';

const getAppLocales = locale => {
  const getLocaleUrl = `/api/v2/app-localization?locale=${locale}`;

  return marketPlaceInstance.get(getLocaleUrl);
};

export default {
  getAppLocales,
};

/* eslint-disable no-undef */

// Marketplace API
export const getMarketPlaceBaseUrl = () => process.env.REACT_APP_MARKET_API_URL;
export const getMarketPlaceApiKey = () => process.env.REACT_APP_MARKET_API_KEY;

// 4ART API
export const get4artBaseUrl = () => process.env.REACT_APP_FourART_API_URL;
export const get4artApiKey = () => process.env.REACT_APP_FourART_API_KEY;

// IDNow
export const getIdNowCustomerId = () => process.env.REACT_APP_IDNOW_CUSTOMER_ID;
export const getIdNowUrl = () => process.env.REACT_APP_IDNOW_URL;

// Blockchain
export const GET_MEW_CONTRACT_ADDRESS = () => process.env.REACT_APP_MEW_CONTRACT_ADDRESS;
export const GET_MEW_CHAIN = () => process.env.REACT_APP_MEW_CHAIN;
export const GET_MEW_CHAIN_ID = () => process.env.REACT_APP_MEW_CHAIN_ID;
export const GET_MEW_INFURA_ID = () => process.env.REACT_APP_MEW_INFURA_ID;
export const GET_MEW_RPC_URL = () => process.env.REACT_APP_MEW_RPC_URL;
export const GET_CONTACT_ABI_FILE_NAME = () => process.env.REACT_APP_CONTACT_ABI_FILE_NAME;

// Environment
export const GET_PLATFORM = () => process.env.REACT_APP_PLATFORM;
export const GET_MARKETPLACE_IDENTIFIER = () => process.env.REACT_APP_MARKETPLACE_IDENTIFIER;

// Defualt Values
export const getGuestUserToken = () => process.env.REACT_APP_API_GUEST_USER_TOKEN;
export const GET_DEFAULT_TITLE = () => process.env.REACT_APP_DEFAULT_TITLE;

// PayPal Checkout + Login with PayPal
// PayPal Live
export const GET_PAYPAL_CLIENT_ID = () => process.env.REACT_APP_PAYPAL_CLIENT_ID;
export const GET_PAYPAL_CLIENT_SECRET = () => process.env.REACT_APP_PAYPAL_CLIENT_SECRET;
export const GET_PAYPAL_CURRENCY = () => process.env.REACT_APP_PAYPAL_CURRENCY;
export const GET_PAYPAL_REDIRECT_URL = () => process.env.REACT_APP_PAYPAL_REDIRECT_URL;

// PayPal Dev
export const GET_PAYPAL_SANDBOX_CLIENT_ID = () => process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID;
export const GET_PAYPAL_SANDBOX_CLIENT_SECRET = () => process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_SECRET;
export const GET_PAYPAL_SANDBOX_CURRENCY = () => process.env.REACT_APP_PAYPAL_SANDBOX_CURRENCY;
export const GET_PAYPAL_SANDBOX_REDIRECT_URL = () => process.env.REACT_APP_PAYPAL_SANDBOX_REDIRECT_URL;

// PayPal Environment
export const GET_PAYPAL_ENABLE_DEBUGGING = () => process.env.REACT_APP_PAYPAL_ENABLE_DEBUGGING;
export const GET_PAYPAL_USE_SANDBOX = () => process.env.REACT_APP_PAYPAL_USE_SANDBOX;

// Google ReCaptcha
export const GET_GOOGLE_RECAPTCHA_SITE_KEY = () => process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

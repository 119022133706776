import { GET_PLATFORM } from '../helper/envVariables/envVariables';
import { PLATFORMS } from '../utils/enums';
import app from '../app.json';

// LOCAL STORAGE CACHE TIME (in minutes)
export const LOCALSTORAGE_CACHE_TIMES = {
  THEME_SETTINGS: 60 * 6,
  LOCALIZATION: 60 * 6,
  RATES: 10,
  TAGS: 5,
  FILTERS: 5,
  BLOCKCHAINS: 15,
  SETTINGS: 60 * 6,
};

export const BLOCKED_LOGIN_TIME_UPDATE_INTERVAL = 15 * 1000; // 15 seconds

export const LEGAL_DOCUMENTS_URL = app.links.legal_docs_base_url[GET_PLATFORM()];

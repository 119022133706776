import logo from '../assets/icons/logo.png';
import mainLogo from '../assets/icons/main-logo.png';

import logoGif from '../assets/gifs/logo.gif';

const getMainLogo = () => mainLogo;

const getLogo = () => logo;

const getLogoGif = () => logoGif;

const getNotificationIcon = () => logo;

const getQRCodeIcon = () => logo;

export { getMainLogo, getLogo, getLogoGif, getNotificationIcon, getQRCodeIcon };

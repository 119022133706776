import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import PropTypes from 'prop-types';

const AlertBox = props => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <div className='alertBox-wrapper'>
          <DialogTitle id='alert-dialog-title' className='alertBox-header'>
            {props.title}
          </DialogTitle>
          <DialogContent className='alertBox-body'>
            <div>{props.body}</div>
          </DialogContent>
          <DialogActions className='alertBox-footer'>
            {props.textForNo && (
              <Button
                onClick={() => {
                  props.onClickNo();
                }}>
                {props.textForNo}
              </Button>
            )}
            <Button
              onClick={() => {
                props.onClickYes();
              }}>
              {props.textForYes}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

AlertBox.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.any,
  textForYes: PropTypes.string,
  textForNo: PropTypes.string,
  onClickYes: PropTypes.func,
  onClickNo: PropTypes.func,
};
export default AlertBox;

export const en = {
  go_to_wallet_popup_msg_transfer:
    "You don't have enough %@ in your wallet to make a transfer of %@ %@. Please transfer min. %@ %@ to your wallet",
  go_to_wallet_popup_msg:
    "You don't have enough %@ in your wallet to make a transfer of %@ %@. Please transfer min. %@ %@ to your wallet",
  cancel: 'Cancel',
  confirm: 'Confirm',
  transfer_coin_popup_confirmation: "Are you sure, You want to transfer %@ %@ to %@ for '%@'?",
  home: 'Home',
  my_profile: 'My profile',
  notification: 'Notifications',
  my_biddings: 'My biddings',
  my_offers: 'My offers',
  my_art: 'My art',
  support_and_faqs: 'Support and FAQs',
  legal: 'Legal',
  logout: 'Logout',
  support_and_faq_message: "If you've got any questions, you can contact our support at any time",
  app_online_guide: '4ARTapp ONLINE GUIDE',
  contact_company: 'Contact 4ARTechnologies',
  '4art_address': '4ARTechnologies AG,  Turmstr. 30, CH-6312 Steinhausen',
  supported_devices: 'Supported devices',
  thankyou_message: 'Thank you for using the 4ARTapp. Do you like it? You may send us your feedback by using the button below.',
  send_feedback: 'Send feedback',
  login: 'Login',
  forgot_password: 'Forgot password',
  register: 'registration',
  email: 'E-Mail',
  country: 'Country',
  password: 'Password',
  no_internet_connection: 'No internet connection',
  company_gallery_collection: 'Company / Gallery / Collection',
  first_name: 'First name',
  last_name: 'Last name',
  read_terms_and_conditions: 'I read the Terms & Conditions and accept it',
  read_privacy_policy: 'I read the Privacy Policy and accept it',
  empty_password_error: 'Password field cannot be empty',
  empty_email_error: 'E-mail cannot be empty',
  empty_value_error: 'This field cannot be empty',
  invalid: 'Invalid',
  invalid_email: 'The E-mail-address you provided is invalid',
  password_length_error: 'The given password is too short. Please choose one with at least 6 characters',
  empty_first_name_error: 'First Name cannot be empty',
  empty_last_name_error: 'Last Name cannot be empty',
  required: 'Required',
  edit_profile: 'Edit profile',
  marketplace_balance: 'Marketplace balance',
  qr_code: 'QR-Code for key transfer',
  import_wallet_key: 'Scan QR-Code for existing wallet keys',
  import_wallet_key_msg:
    'In order to be able to use your existing application wallet, please open the "EXPORT WALLET KEYS" item in your profile in the 4ARTapp and scan the QR code shown below.',
  personal_data: 'Personal data',
  fill_all_fields: 'Please fill in all the fields.',
  change_password: 'Change password',
  street_house_number: 'Street / House number',
  zip: 'Zip',
  city: 'City',
  vat_number: 'VAT Number',
  success_user_updated: 'Success! user updated successfully',
  actual_password: 'Actual password',
  new_password: 'New password',
  repeat_new_password: 'Repeat new password',
  ethereum: 'Ethereum',
  tezos: 'Tezos',
  show_keys: 'Show keys',
  hide_keys: 'Hide keys',
  public_key: 'Public key',
  private_key: 'Private key',
  key_copied: 'Key copied',
  recharge: 'Recharge',
  transfer: 'Transfer',
  registraion_completed_msg:
    'We have just sent you a confirmation email. Please confirm your registration by clicking on the link in the confirmation email to complete the registration.',
  terms_and_conditions: 'Terms and conditions',
  privacy_policy: 'Privacy policy',
  imprint: 'Imprint',
  start_user_identifications: 'Start user identification',
  market_place_listing_title: '4ART NFT marketplace',
  wallet_info_text_web: 'On the next screen a wallet will be created for you.\nStore your wallet keys in secure place.',
  artist: 'Artist',
  blockchain: 'Blockchain',
  price: 'Price',
  sold: 'Sold',
  place_new_bid: 'Place new bid',
  place_bid: 'Place a bid',
  bids_placed: 'Bids placed',
  new: 'New',
  status: 'Status',
  year: 'Year',
  artist_royalties: 'Artist royalties',
  address: 'Address',
  transfer_token_confirmation_msg:
    'Please check again that the public key you entered is correct. Note that there is a Gas cost of %@ ETH for the transfer. The amount credited to your wallet will be reduced by this amount.',
  install_metamask: 'Please install MetaMask browser extension',
  no_metamask_or_connection_error: 'MetaMask browser extension not found or connection error',
  insufficient_ethers_balance: 'Insufficient ethers balance',
  error_fetching_ethers_balance: 'Error fetching ethers balance',
  insufficient_tokens_coins_balance: 'Insufficient tokens/coins balance',
  error_fetching_tokens_coins_balance: 'Error fetching tokens/coins balance',
  error_fetching_estimates: 'Error fetching estimates',
  transfer_canceled: 'Transfer canceled',
  all_transactions_completed_successfully: 'All transactions have been completed successfully',
  transaction_failed: 'Transaction failed',
  transferring_related_errors_occurred: 'Transferring related errors occurred',
  an_unknown_error_occurred: 'Unable to process your request at the moment. Please try it again!',
  success: 'Success',
  ok: 'Ok',
  error: 'Error',
  wallet_used_for_payment_nft_in_app_wallet:
    'Please note that %@ can only be used for payment. The NFT+(s) are transferred to your 4ART-Application-Wallet',
  note: 'Note',
  confirmation: 'Confirmation',
  read: 'Read',
  old: 'Old',
  mark_as_read: 'Mark as read',
  see_inquiry: 'See inquiry',
  transfer_now: 'Transfer now',
  kyc_not_confirmed: 'Your KYC-process is not confirmed yet.',
  kyc_may_take_hours: 'Please note that the KYC check may take up to 24 hours.',
  complete_60_mins: 'Usually it will be completed within 60 Minutes.',
  select_artwork_to_see_details: 'Select Artwork to see details',
  connection_error: 'Connection error',
  min_six_char: 'Minimum six characters',
  allowed_cahr_set_special_characters: 'Special characters (@,$,%,^,*,.....)',
  allowed_cahr_set_numbers: 'Number (0,1,2,3...9)',
  allowed_cahr_set_capital_alphabets: 'Capital characters (A-Z)',
  reset_password_title: 'Reset password',
  reset_password_instruction: 'We just sent you an E-Mail. There is a Link in that E-Mail to reset your Password',
  personal_data_message: 'Here you can see and edit your personal data',
  save: 'Save',
  change_email: 'Change E-Mail',
  password_match: 'Password match',
  confirm_password: 'Confirm password',
  select_wallet: 'Select wallet',
  qr: 'QR Code',
  qr_code_subtitle: 'QR-Code of your Private-Key and Public-Key',
  encrypted: 'Encrypted',
  decrypted: 'Decrypted',
  transfer_coins: 'Transfer credit',
  transfer_coins_msg:
    'Please ensure that there are enough Ether in your wallet to be able to pay the transfer costs (e.g. gas costs).',
  fill_balance: 'Fill up balance',
  buy_credit: 'With Application Credits',
  buy_4art_coins_with_credits: 'Buy 4ART coins with credits',
  buy_4art_coins_with_credits_msg: 'How many 4ART coins do you want to buy?',
  actual_balance: 'Actual balance',
  nft_type: 'NFT Type',
  filter_title: 'Filter',
  filter_msg: 'Filter your NFTs according to one or more criteria.',
  search_term: 'Search term',
  us_dollars: 'US Dollars',
  apply_filter: 'Apply filters',
  code_sent_info:
    'We have sent you a code to your new e-mail address. Please enter the code to confirm the change. After that you have to log in again',
  code: 'Code',
  amount: 'Amount',
  'Caution!': 'Caution!',
  caution_message: 'Do never share this QR-Code with others',
  accept_bid_popup: "Do you want to accept the bid of %@ %@ for '%@'?",
  your_paypal_account: 'Your PayPal account',
  paypal_email: 'PayPal email address',
  bid_has_been_accepted: 'Bid has been accepted',
  email_field_required: 'Email field required',
  paypal_email_not_setup_please_authorize:
    'Your PayPal account is not verified yet. Please use "Login with PayPal" to verify your account now',
  loading_paypal_sdk: 'Loading PayPal...',
  failed_to_update_user_paypal_email:
    'Unfortunately, it was not possible to update your Paypal email address. Please try it again',
  login_with_paypal_email_validation_success:
    "Thank you for verifying your Paypal account. Please note, that the bidding hasn't been accepted by you yet. To do so, you have to the Button again. Do you want to proceed?",
  redirecting_to_offers_page: 'Redirecting to NFT offers page...',
  no_transactions_to_process: 'There is no transaction to process!',
  paypal_canceled_first_transaction_toast:
    'The payment process for "%@" either encountered an error or you canceled it. Please start the process again and transfer the outstanding amount of "%@" USD',
  paypal_canceled_second_transaction_toast:
    'The payment process for "%@" is not yet complete. Please proceed and transfer the outstanding amount of "%@" USD',
  payment_processing_via_paypal: 'Payment processing via PayPal',
  failed_to_load_paypal_sdk: 'Failed to load PayPal. Please try it again!',
  processing_transactions: 'Processing Transactions',
  current_transaction: 'Current transaction',
  paying_amount_to_receiver_msg: 'Paying %@ to %@',
  are_you_sure_msg_paypal:
    'The payment process is divided into two transactions. The first will transfer %@ USD to %@. On the second transaction, the commission of %@ USD will be transferred to %@',
  '4art_commission': '4ART Commission',
  royalty: 'Royalty',
  owner_amount: 'Owner amount',
  accept_artwork_confirmation_msg: 'Are you sure you want to accept the request?',
  reject_artwork_confirmation_msg: 'Are you sure you want to reject the request?',
  remove: 'Remove',
  accept: 'Accept',
  reject: 'Reject',
  chat: 'Chat',
  conversations: 'Conversations',
  tezos_wallet_private_keys_not_found: 'Tezos wallet private key not found',
  tezos_wallet_public_keys_not_found: 'Tezos wallet public key not found',
  ethereum_wallet_private_keys_not_found: 'Ethereum wallet private key not found',
  ethereum_wallet_public_keys_not_found: 'Ethereum wallet public key not found',
  user_email_not_found: 'User email not found',
  bid_has_been_placed: 'Bid has been placed',
  remove_bid_message: 'Are you sure you want to remove your bid?',
  no: 'No',
  wallet_header: 'Ether wallet',
  tezos_wallet: 'Tezos wallet',
  title: 'Title',
  material: 'Material',
  base_material: 'Base material',
  height: 'Height',
  width: 'Width',
  depth: 'Depth',
  resolution: 'Resolution',
  format: 'Format',
  no_more_records: 'No more records',
  loading: 'Loading',
  pay_now: 'Pay now',
  copied_into_clipboard: 'Copied into clipboard',
  buy_credits_with_coin: 'Buy %@ 4ARTCOINS now',
  minimum_amount_200_credits: 'Minimum 200 Credits',
  push_to_marketplace: 'Push to marketplace',
  push_to_marketplace_message: 'Do you want to push this NFT to Marketplace?',
  services: 'Services',
  nft_image_selection: 'NFT image selection',
  image_selection: 'Please select an image to display the banner and thumbnail in the marketplace.',
  image_size: 'It should have at least 600x600 pixels to be displayed properly.',
  continue: 'Continue',
  set_price: 'Set Price',
  must_be_number: 'Amount must be a number',
  price_must_be_postive: 'Price must be a positive number',
  max_x_characters_allow: 'Max %@ characters allowed',
  description: 'Description',
  write_the_description: 'Write the description',
  sort_by: 'Sort By',
  random: 'Random',
  hot: 'Hot',
  place_nft_in_marketplace: 'Place NFT in Marketplace',
  next: 'Next',
  previous: 'Previous',
  page_x_of_y: 'Page %@ of %@',
  logout_confirmation_msg: 'Are you sure you want to logout?',
  yes: 'Yes',
  remove_from_marketplace: 'Remove from Marketplace',
  remove_from_marketplace_message: 'Do you want to remove this NFT from marketplace?',
  guest_user_info_title: 'Thank you for your interest',
  guest_user_info_description: 'In order to be able to use all functions, please login with a user account or register a new one',
  dismiss: 'Dismiss',
  choose_artwork: 'Choose artwork',
  copied: 'Copied',
  unknown: 'Unknown',
  description_in_en: 'Description in English (INTERNATIONAL)',
  description_in_de: 'Description in German',
  description_in_es: 'Description in Spanish',
  description_in_fr: 'Description in French',
  description_in_ru: 'Description in Russian',
  description_in_ar: 'Description in Arabic',
  description_in_it: 'Description in Italian',
  description_in_zh: 'Description in Chinese',
  description_in_ja: 'Description in Japanese',
  description_in_pt: 'Description in Portagese',
  overview: 'Overview',
  documents: 'Documents',
  provenance: 'Provenance',
  track: 'Track',
  request: 'Request',
  requested: 'Requested',
  user_identification_header: 'User identification',
  user_identification_title:
    'Since we register artworks irrevocably, we have to ensure your identity. For this we need the front and back of your ID. All data is treated confidentially and processed with high encryption',
  information: 'Information',
  save_wallet: 'Save Wallet',
  thank_registration: 'Thank you for your registration.',
  empty_field_error: 'This field cannot be empty',
  wallet_not_available:
    'It seems, that you have logged in first time on this device. Do you want to Scan your private key via QR-Code?',
  wrong_login_attempts_message: 'The login is not possible for %@ minute(s) due to multiple incorrect entries',
  import_keys_manually: 'Import keys manually',
  offer_detail: 'Detail',
  bidding: 'Bidding',
  accepted: 'Accepted',
  go_to_wallet: 'Go to Wallet',
  wallet_type: 'Wallet type',
  delete_account_btn_text: 'Delete account',
  delete_account_confirmation_text: 'Are you sure you want to delete your 4ART-account?',
  warning: 'Warning',
  private_key_successfully_imported: 'Private key successfully imported',
  wallet_not_found: 'Wallet not found. Please create one and proceed afterwards',
  transactions_status: 'Transactions status',
  import_wallet_keys: 'Import wallet keys',
  import_wallet_keys_from_app: 'Import wallet keys from 4ARTapp',
  enter_wallet_keys_manually: 'Enter wallet keys manually',
  enter_wallet_keys_manually_msg:
    'In order to be able to use your existing wallet in the web marketplace, please enter you wallet private key in field below',
  import_wallet_keys_from_webcam_qr: 'Scan QR for wallet keys',
  import_wallet_keys_from_webcam_qr_msg: 'In order to scan your wallet keys, please take key QR code near to webcam',
  no_result: 'No result',
  open_webcam: 'Open webcam',
  scan_private_key: 'Please scan the private key of you wallet',
  type_here: 'Type here...',
  select_payment_method_to_proceed: 'Select payment method to proceed',
  scanned_key_is_not_private_key: 'Scanned key is not private',
  invalid_key_pair_public_key_not_matched: 'Invalid key pair, public key does not match with wallet public key',
  submit: 'Submit',
  no_tezos_wallet_please_create: "It seems that you don't have Tezos wallet, please create one.",
  create_wallet: 'Create wallet',
  scan_keys: 'Scan keys',
  import: 'Import',
  send: 'Send',
  payment_method: 'Payment method',
  payment_methods: 'Payment methods',
  price_per_token: 'Price per token',
  must_be_postive: 'Provide positive number',
  must_be_a_number: 'Provide numbers only',
  tokens: 'Tokens',
  quantity: 'Quantity',
  selling_token_message: 'How many token do you want to sell on Marketplace?',
  available: 'Available',
  total_price_all_tokens: 'Total price of all tokens',
  n_a: 'n/a',
  total_price: 'Total price',
  buy_now: 'Buy now',
  currency_selection: 'Currency selection',
  nft_chunks_fetching_error: 'Fetching NFT baskets encountered an error',
  available_chunks: 'Available baskets',
  no_chunks_found: 'No baskets found',
  new_chunk: 'New basket',
  chunk_data_error: 'Error in basket data',
  remove_multiple_token_msg: 'All token will be removed from marketplace. Do you want to proceed?',
  already_available_on_marketplace: 'Already available on Marketplace',
  archive_notification: 'Archived notifications',
  activities: 'Activities',
  same_price_chunk_available: 'A package with the same price you just entered is already offered on the marketplace.',
  merge_in_existing_chunk_confirmation_msg: 'Are you sure, You want to merge into existing basket?',
  enter_bidding_amount: 'Please enter your bidding amount',
  merge: 'Merge',
  change_price: 'Change price',
  field_value_greater_than_or_equal: 'Please enter value which is greater than or equal to %@',
  field_value_less_than_or_equal: 'Please enter value which is less than or equal to %@',
  forgot_password_title: 'Please enter your E-Mail-Address to reset your Password',
  wallet_address: 'Wallet Address',
  small_image_resolution_message: 'The resolution of selected image is too small. Please select another image',
  pending: 'Pending',
  inprogress: 'In progress',
  complete: 'Complete',
};

export default en;

import axios from 'axios';
import { get4artBaseUrl, get4artApiKey } from '../helper/envVariables/envVariables';
import { toast } from 'react-toastify';
import { getToken, getLocale } from './helpers/token';

const fourArtInstance = axios.create({
  baseURL: get4artBaseUrl(),
  headers: {
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    appApiKeyMp: get4artApiKey(),
  },
});

fourArtInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.Authorization = getToken();
    config.headers.locale = getLocale();
    config.params = { ...config.params, locale: getLocale() };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

fourArtInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // 'Network Error' meessage also return incase of withCredintials pilot request failure
    // double check to verify that browser internet is offline and throw toast meessage
    if (!window.navigator.onLine || (!error.response && error.message === 'Network Error')) {
      toast.error('No internet connection');
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error);
    return error.response;
  }
);

export default fourArtInstance;

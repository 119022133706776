import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import userApi from './services/user/userApi';

const firebaseConfig = {
  apiKey: 'AIzaSyBjlkY19UrGi8EWePD5sK-KM0LZoe-g-jY',
  authDomain: 'android4artapp.firebaseapp.com',
  projectId: 'android4artapp',
  storageBucket: 'android4artapp.appspot.com',
  messagingSenderId: '796241197749',
  appId: '1:796241197749:web:b5fd2510e99cf55d6c5cfc',
  measurementId: 'G-DFB440FDQH',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = async setTokenFound => {
  let currentToken = '';
  let preToken = localStorage.getItem('push_token');
  try {
    currentToken = await getToken(messaging);
    if (currentToken) {
      if (preToken !== currentToken) {
        await userApi.addDeviceToken(currentToken);
        localStorage.setItem('push_token', currentToken);
        setTokenFound(true);
      } else {
        setTokenFound(true);
      }
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log('An error occureed while retreiving token. ', error);
  }
  return currentToken;
};

// getToken(messaging)
//   .then(currentToken => {
//     debugger;
//     if (currentToken) {
//       console.log('Firebase Token', currentToken);
//     } else {
//       // Show permission request UI
//       console.log('No registration token available. Request permission to generate one.');
//       // ...
//     }
//   })
//   .catch(err => {
//     console.log('An error occurred while retrieving token. ', err);
//     // ...
//   });

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });

import React from 'react';

const MYProfile = React.lazy(() => import('./pages/myProfile/Myprofile'));
const ProfileList = React.lazy(() => import('./pages/profileList/ProfileList'));
const Notifications = React.lazy(() => import('./pages/notfications/Notifications'));
const ProfileData = React.lazy(() => import('./pages/profile/Profile'));
const MyBidding = React.lazy(() => import('./pages/myBidding/MyBidding'));
const MyOffer = React.lazy(() => import('./pages/myOffer/MYOffer'));
const MyArt = React.lazy(() => import('./pages/myArt/MyArt'));
const PersonalInfo = React.lazy(() => import('./pages/personalInfo/PersonalInfo'));
const ChangePassword = React.lazy(() => import('./pages/changePassword/ChangePassword'));
const ChangeEmail = React.lazy(() => import('./pages/changeEmail/ChangeEmail'));
const ChangeEmailVerification = React.lazy(() => import('./pages/changeEmailVerification/ChangeEmailVerification'));
const ResetPassword = React.lazy(() => import('./pages/resetPassword/ResetPassword'));
const ConfirmPassword = React.lazy(() => import('./pages/confirmPassword/ConfirmPassword'));
const WalletList = React.lazy(() => import('./pages/walletsList/WalletList'));
const MarketPlaceBalance = React.lazy(() => import('./pages/marketPlaceBalance/MarketPlaceBalance'));
const EthereumKeysExchange = React.lazy(() => import('./pages/ethereumKeysExchange/EthereumKeysExchange'));
const TezosKeysExchnage = React.lazy(() => import('./pages/tezosKeysExchange/TezosKeysExchange'));

const Transferbalance = React.lazy(() => import('./pages/transferBalance/Transferbalance'));
const idUpload = React.lazy(() => import('./pages/idUpload/IdUpload'));
const ImportWalletKeys = React.lazy(() => import('./pages/importWalletKeys/ImportWalletkeys'));
const ImportKeysFromApp = React.lazy(() => import('./pages/importKeysFromApp/ImportKeysFromApp'));
const EnterKeysManually = React.lazy(() => import('./pages/enterKeysManually/EnterKeysmanually'));
const ImportkeysFromWebcamQr = React.lazy(() => import('./pages/importKeysFromWebcam/ImportKeysFromWebcam'));
const WalletInfo = React.lazy(() => import('./pages/walletInfo/WalletInfo'));
const EtherWalletCreation = React.lazy(() => import('./pages/etherWalletCreation/EtherWalletCreation'));
const TezosWalletCreation = React.lazy(() => import('./pages/tezosWalletCreation/TezosWalletCreation'));
const Thankyou = React.lazy(() => import('./pages/thankyou/Thankyou'));
const CreditsBalance = React.lazy(() => import('./pages/creditsBalance/CreditsBalance'));
const ConvertCreditsIntoCoins = React.lazy(() => import('./pages/convertCreditsIntoCoins/ConvertCreditsIntoCoins'));
const IdnowVerification = React.lazy(() => import('./pages/idnowVerfication/IdnowVerification'));
const nftImageSelection = React.lazy(() => import('./pages/pushNftToMarketplace/NftImageSelection'));
const pushTomarketplace = React.lazy(() => import('./pages/pushNftToMarketplace/PushToMarketplace'));
const MyOfferDetail = React.lazy(() => import('./pages/myOfferDetail/MyOfferDetail'));
const ChatList = React.lazy(() => import('./pages/chatList/ChatList'));
const Chat = React.lazy(() => import('./pages/chat/Chat'));

const routes = [
  { path: '/my-profile', name: 'MY PROFILE', component: MYProfile, type: 'private' },
  { path: '/profile-list', name: 'PROFILE LIST', component: ProfileList, type: 'private' },
  { path: '/profile', name: 'EDIT PERSONAL INFO', component: ProfileData, type: 'private' },
  { path: '/notifications', name: 'NOTIFICATIONS', component: Notifications, type: 'private' },
  { path: '/biddings', name: 'MY BIDDINGS', component: MyBidding, type: 'private' },
  { path: '/offers', name: 'MY OFFERS', component: MyOffer, type: 'private' },
  { path: '/my-artwork', name: 'MY ART', component: MyArt, type: 'private' },
  { path: '/personal-info', name: 'PERSONAL INFO', component: PersonalInfo, type: 'private' },
  { path: '/change-password', name: 'CHANGE PASSWORD', component: ChangePassword, type: 'private' },
  {
    path: '/change-email',
    name: 'CHANGE EMAIL',
    component: ChangeEmail,
    type: 'private',
  },
  {
    path: '/change-email-verification',
    name: 'CHANGE EMAIL VERIFICATION',
    component: ChangeEmailVerification,
    type: 'private',
  },
  {
    path: '/confirm-password',
    name: 'CONFIRM PASSWORD',
    component: ConfirmPassword,
    type: 'private',
  },
  {
    path: '/reset-password',
    name: 'RESET PASSWORD',
    component: ResetPassword,
    type: 'private',
  },
  {
    path: '/myOffer-detail',
    name: 'OFFER DETAIL',
    component: MyOfferDetail,
    type: 'private',
  },
  {
    path: '/wallets-list',
    name: 'WALLET LIST',
    component: WalletList,
    type: 'private',
  },
  {
    path: '/import-wallet-keys',
    name: 'IMPORT WALLET KEYS',
    component: ImportWalletKeys,
    type: 'private',
  },
  {
    path: '/import-keys-from-app',
    name: 'IMPORT Wallet KEYS FROM APP',
    component: ImportKeysFromApp,
    type: 'private',
  },
  {
    path: '/enter-keys-manaully',
    name: 'ENTER WALLET KEYS MANUALLY',
    component: EnterKeysManually,
    type: 'private',
  },
  {
    path: '/import-keys-from-webcam-qr',
    name: 'IMPORT WALLET KEYS FROM WEBCOM TO SCAN QR',
    component: ImportkeysFromWebcamQr,
    type: 'private',
  },
  {
    path: '/ethereum-keys-exchange',
    name: 'ETHEREUM KEYS EXCHANGE',
    component: EthereumKeysExchange,
    type: 'private',
  },
  {
    path: '/tezos-keys-exchange',
    name: 'TEZOS KEYS EXCHANGE',
    component: TezosKeysExchnage,
    type: 'private',
  },
  {
    path: '/market-place-balance',
    name: 'MARKETPLACE BALANCE',
    component: MarketPlaceBalance,
    type: 'private',
  },
  {
    path: '/transfer-balance',
    name: 'TRANSFER BALANCE',
    component: Transferbalance,
    type: 'private',
  },

  {
    path: '/id-upload',
    name: 'ID UPLOAD',
    component: idUpload,
    type: 'private',
  },
  {
    path: '/idnowVerification',
    name: 'IDNOW  VERIFICATION',
    component: IdnowVerification,
    type: 'private',
  },
  {
    path: '/wallet-info',
    name: 'WALLET DETAILS',
    component: WalletInfo,
    type: 'private',
  },
  {
    path: '/ether-wallet-creation',
    name: 'ETHER WALLET REGISTER',
    component: EtherWalletCreation,
    type: 'private',
  },
  {
    path: '/tezos-wallet-creation',
    name: 'TEZOS WALLET REGISTER',
    component: TezosWalletCreation,
    type: 'private',
  },
  {
    path: '/thankyou',
    name: 'THANK YOU',
    component: Thankyou,
    type: 'private',
  },
  {
    path: '/credits-balance',
    name: 'CREDITS BALANACE',
    component: CreditsBalance,
    type: 'private',
  },
  {
    path: '/convert-credits-into-coins',
    name: 'CONVERT CREDITS INTO COINS',
    component: ConvertCreditsIntoCoins,
    type: 'private',
  },

  {
    path: '/nft-image-selection',
    name: 'NFT IMAGE SELETION',
    component: nftImageSelection,
    type: 'private',
  },
  {
    path: '/push-to-marketplace',
    name: 'PUSH TO MARKETPLACE',
    component: pushTomarketplace,
    type: 'private',
  },
  {
    path: '/chatList',
    name: 'CHAT LIST',
    component: ChatList,
    type: 'private',
  },
  {
    path: '/chat',
    name: 'CHAT',
    component: Chat,
    type: 'private',
  },
];

export default routes;

import React, { useEffect, useState } from 'react';
import { getFirebaseToken } from '../firebase';

const PushNotification = props => {
  const [tokenFound, setTokenFound] = useState(false);

  // console.log('Token Found', tokenFound);

  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getFirebaseToken();
      if (data) {
        // console.log('Token is', data);
      }
      return data;
    }
    tokenFunc();
  }, [setTokenFound]);
  return <></>;
};

export default PushNotification;

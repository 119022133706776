import marketPlaceInstance from '../marketPlaceInstance';
import fourArtInstance from '../fourArtInstance';

const getThemeSetting = () => {
  const themeSettingUrl = `/api/v2/app/setting`;
  return marketPlaceInstance.get(themeSettingUrl);
};

const setuserLocale = lang => {
  const userlocaleUrl = '/api/v1/marketplace/user/locale';
  const params = {
    locale: lang,
  };
  return fourArtInstance.post(userlocaleUrl, params);
};

export default { getThemeSetting, setuserLocale };

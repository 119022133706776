import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { onMessageListener } from './firebase';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { getActiveRoute } from './utils/utils';
import { HTTP_STATUS_CODES, LOCALSTORAGE_CACHE_ITEMS, PLATFORMS } from './utils/enums';
import { setSettingInCache, getSettingFromCache, setCacheTime } from './helper/localStorageFun/localStorageFun';
import { toast } from 'react-toastify';
import { isCacheExpired } from './utils/cache';
import { LOCALSTORAGE_CACHE_TIMES } from './config/constants';
import { useDispatch } from 'react-redux';
import { loaderActions } from './store/loader/loader-slice';
import { useTranslation } from 'react-i18next';
import { GET_PLATFORM } from './helper/envVariables/envVariables';

import settingApi from './services/setting/setting';
import app from './app.json';
import PushNotification from './pushNotification/PushNotification';
import ReactNotification from './pushNotification/ReactNotification';
import Loader from './components/loader/Loader';
import ToastMessages from './components/toastMessages/ToastMessages';

import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './App.css';

const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'));
const Login = React.lazy(() => import('./pages/login/Login'));
const Register = React.lazy(() => import('./pages/register/Register'));
const Home = React.lazy(() => import('./pages/home/Home'));
const Legal = React.lazy(() => import('./pages/legal/Legal'));
const Support = React.lazy(() => import('./pages/support/Support'));
const ForgotPassword = React.lazy(() => import('./pages/forgotPassword/ForgotPassword'));
const TermsAndConditions = React.lazy(() => import('./pages/termsAndConditions/TermsAndConditions'));
const PrivacyPolicy = React.lazy(() => import('./pages/privacyPolicy/PrivacyPolicy'));
const Imprint = React.lazy(() => import('./pages/imprint/Imprint'));

export const pubblicRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Registration',
    component: Register,
  },
  {
    path: '/home',
    name: `${app.company_short_name} Marketplace Home`,
    component: Home,
  },
  {
    path: '/forgot-password',
    name: 'FORGOT Password',
    component: ForgotPassword,
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal,
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
  },
  {
    path: '/privacy-policy',
    name: 'PRIVACY POLICY',
    component: PrivacyPolicy,
  },
  {
    path: '/imprint',
    name: 'IMPRINT',
    component: Imprint,
  },
  {
    path: '/terms-and-conditions',
    name: 'TERMS AND CONDITIONS',
    component: TermsAndConditions,
  },
];

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });

  // console.log(show, notification);

  onMessageListener()
    .then(payload => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch(error => console.log('failed: ', error));

  const updateCSSVariables = theme => {
    document.documentElement.style.setProperty('--primary-btn-bg-color', theme.button_primary_background_color);
    document.documentElement.style.setProperty('--primary-btn-text-color', theme.button_primary_text_color);

    document.documentElement.style.setProperty('--secondary-btn-bg-color', theme.button_secondary_background_color);
    document.documentElement.style.setProperty('--secondary-btn-text-color', theme.button_secondary_text_color);
    document.documentElement.style.setProperty('--secondary_btn_border_color', theme.button_secondary_border_color);

    document.documentElement.style.setProperty('--tertiary-btn-bg-color', theme.button_tertiary_background_color);
    document.documentElement.style.setProperty('--tertiary_btn_text_color', theme.button_tertiary_text_color);

    // document.documentElement.style.setProperty(
    //   '--danger-btn-bg-color',
    //   theme.button_danger_background_color || theme.button_secondary_background_color
    // );
    // document.documentElement.style.setProperty(
    //   '--danger-btn-text-color',
    //   theme.button_danger_text_color || theme.button_secondary_text_color
    // );
    // document.documentElement.style.setProperty(
    //   '--danger_btn_border_color',
    //   theme.button_danger_border_color || theme.button_secondary_border_color
    // );
    theme.button_danger_background_color &&
      document.documentElement.style.setProperty('--danger-btn-bg-color', theme.button_danger_background_color);
    theme.button_danger_text_color &&
      document.documentElement.style.setProperty('--danger-btn-text-color', theme.button_danger_text_color);
    theme.button_danger_border_color &&
      document.documentElement.style.setProperty('--danger_btn_border_color', theme.button_danger_border_color);
  };

  const getThemeSetting = async () => {
    const settings = getSettingFromCache();
    if (!settings || (settings && isCacheExpired(LOCALSTORAGE_CACHE_ITEMS.SETTINGS, LOCALSTORAGE_CACHE_TIMES.SETTINGS))) {
      try {
        dispatch(loaderActions.setLoader());
        const response = await settingApi.getThemeSetting();
        dispatch(loaderActions.resetLoader());

        const statusCode = response?.data?.code || response?.status;
        if (statusCode === HTTP_STATUS_CODES.OK) {
          setSettingInCache(response.data.data);
          updateCSSVariables(response.data.data);
          setCacheTime('settings');
        } else {
          toast.error(response?.data?.message || t('an_unknown_error_occurred'));
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || error?.message || error || t('an_unknown_error_occurred'));
      }
    } else {
      updateCSSVariables(settings);
    }
  };

  useEffect(() => {
    getThemeSetting();

    String.prototype.formatAt = function () {
      return [...arguments].reduce((p, c) => p.replace(/%@/, c), this);
    };

    if (GET_PLATFORM() === PLATFORMS.LIVE) {
      try {
        document.addEventListener('contextmenu', event => {
          event.preventDefault();
        });
      } catch {
        // no-op
      }
    }

    return () => {
      try {
        document.removeEventListener('contextmenu', event => {
          event.preventDefault();
        });
      } catch {
        // no-op
      }
    };
  }, []);

  const closePushNotification = () => {
    setShow(false);
  };
  return (
    // <React.StrictMode>
    //   <React.Suspense fallback={<SpinnerLoading applyViewHeight />}>
    <>
      {show && (
        <ReactNotification title={notification.title} body={notification.body} open={show} onClose={closePushNotification} />
      )}
      <PushNotification />
      <ToastMessages />
      <Loader />

      {getActiveRoute(location.pathname)?.name && (
        <HelmetProvider>
          <Helmet>
            <title>{getActiveRoute(location.pathname)?.name}</title>
          </Helmet>
        </HelmetProvider>
      )}

      <Switch>
        {pubblicRoutes.map(route => {
          return (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              name={route.name}
              render={props => <route.component {...props} />}
            />
          );
        })}

        <Route path='/' name='Home' render={props => <DefaultLayout {...props} />} />
      </Switch>
    </>
    //   </React.Suspense>
    // </React.StrictMode>
  );
}

export default App;

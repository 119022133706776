import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

const ToastMessages = () => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <ToastContainer
          position='top-right'
          autoClose={false}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={3}
          style={{ width: '500px' }}
        />,
        document.getElementById('toast-messages')
      )}
    </React.Fragment>
  );
};

export default ToastMessages;

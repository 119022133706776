import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: { loading: false },
  reducers: {
    setLoader(state) {
      state.loading = true;
    },
    resetLoader(state) {
      state.loading = false;
    },
  },
});

export const loaderActions = loaderSlice.actions;

export default loaderSlice;

export const UserTypes = {
  AUTHENTICATED: 'AUTHENTICATED',
  GUEST: 'GUEST',
};

export const CURRENCY_TYPES = {
  // ETHER: 'ETHEREUM',
  // FOUR_ART_COIN: '4ART_COIN',
  // DOLLAR: 'DOLLAR',
  ETHER: {
    key: 'ethereum',
    fullName: 'Ethereum',
    shortName: 'Ether',
    currencyCode: 'ETH',
    symbol: 'Eth',
    apiValue: 'Ethereum',
    dropdownDisplayText: 'ETH',
  },
  FOUR_ART_COIN: {
    key: '4artcoin',
    fullName: '4ART COIN',
    shortName: '4ARTCoin',
    currencyCode: '4ART',
    symbol: '4Art',
    apiValue: '4artCoin',
    dropdownDisplayText: '4ARTCoin',
  },
  DOLLAR: {
    key: 'dollar',
    fullName: 'US Dollar',
    shortName: 'Dollar',
    currencyCode: 'USD',
    symbol: '$',
    apiValue: 'Dollar',
    dropdownDisplayText: 'USD ($)',
  },
  EURO: {
    key: 'euro',
    fullName: 'Euro',
    shortName: 'Euro',
    currencyCode: 'EUR',
    symbol: '€',
    apiValue: 'Euro',
    dropdownDisplayText: 'Euro',
  },
};

export const FIAT_PAYMENT_METHODS = {
  PAYPAL: 'PAYPAL',
};

export const CRYPTO_PAYMENT_METHODS = {
  APP_WALLET: 'APP_WALLET',
  METAMASK: 'METAMASK',
  MY_ETHER_WALLET: 'MY_ETHER_WALLET',
};

export const LANGUAGE_CODES = {
  en: 'en',
  enUS: 'en-us',
  enGB: 'en-gb',
  de: 'de',
};

// NOTE: values of these statuses are synced/matched with ones from backend (got in estimates API call)
export const TRANSACTION_STATUSES = {
  IN_PROGRESS: 'inprogress',
  COMPLETE: 'complete',
  PENDING: 'pending',
};

// values will reflect same as provided from .env file
export const PLATFORMS = {
  LIVE: 'live',
  PRELIVE: 'prelive',
};

export const NOTIFICATION_EVENTS = {
  ACCEPT_BID: 'accept_bid',
  PLACE_BID_OWNER: 'place_bid_owner',
  CHECK_NFT_ARTWORK_REQUEST: 'check_nft_artwork_request',
  NFT_TRANSFER_BLOCKCHAIN: 'nft_transfer_block_chain',
  REMOVE_NFT: 'remove_nft',
  REQUEST_LOG_ACCEPTED: 'request_log_accepted',
  // MARK_READ: 'mark_as_read',
  PAYPAL_FIRST_CANCEL: 'paypal_first_cancel_event',
  PAYPAL_SECOND_CANCEL: 'paypal_second_cancel_event',
};

export const BLOCKCHAIN_TYPES = {
  BINANCE: 'binance',
  PALM: 'palm',
  ETHEREUM: 'ethereum',
  TEZOS: 'tezos',
};

export const HTTP_STATUS_CODES = {
  OK: 200,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  INVALID_REQUEST: 400,
};

export const LOCALSTORAGE_CACHE_ITEMS = {
  RATES: 'rates',
  THEME_SETTINGS: 'settings',
  LOCALIZATION: 'locales',
  TAGS: 'tags',
  FILTERS: 'filters',
  BLOCKCHAINS: 'blockchains',
  SETTINGS: 'settings',
};

export const WALLET_TYPES = {
  ETHEREUM: {
    apiValue: 'ETH',
    displayText: 'Ethereum',
  },
  TEZOS: {
    apiValue: 'XTZ',
    displayText: 'Tezos',
  },
};

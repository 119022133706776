import routes from '../routes';
import { pubblicRoutes } from '../App';
import { ANIMATION_TIMING } from './constants';

export const getActiveRoute = path => {
  const allRoutes = [...routes, ...pubblicRoutes];
  return allRoutes.find(route => route.path === path);
};

export const getRandomAnimationTiming = () => {
  return ANIMATION_TIMING.sort(() => 0.5 - Math.random())[0];
};

export const getRandomAnimationDuration = itemIndex => {
  if (itemIndex % 4 === 0) {
    return 0;
  } else {
    return parseInt(0, 10) + parseInt(itemIndex % 4, 10);
  }
};

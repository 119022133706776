import userTypes from '../../helper/UserTypes/userTypes';
import { getGuestUserToken } from '../../helper/envVariables/envVariables';
import tokenTypes from '../tokenTypes/tokenType';
import moment from 'moment-timezone';
import { UserTypes } from '../../utils/enums';

// get and set locale
export const setLocale = locale => {
  localStorage.setItem('locale', locale);
};
export const getLocale = () => {
  return localStorage.getItem('locale');
};

// get set and clear id_token
export const setToken = token => {
  localStorage.setItem('id_token', token);
};
export const getToken = () => {
  return localStorage.getItem('id_token');
};
export const clearToken = () => {
  localStorage.removeItem('id_token');
};

// get set and clear user info
export const setUserInfo = userInfo => {
  userInfo = JSON.stringify(userInfo);
  localStorage.setItem('user', userInfo);
};
export const getUserInfo = () => {
  const userInfo = localStorage.getItem('user');

  return JSON.parse(userInfo);
};

export const updateUserInfo = detail => {
  let userInfo = localStorage.getItem('user');
  userInfo = JSON.parse(userInfo);
  userInfo = { ...userInfo, ...detail };
  localStorage.setItem('user', JSON.stringify(userInfo));
};
export const clearUserInfo = () => {
  const userInfo = null;
  localStorage.setItem('user', userInfo);
};

// get set and clear user type

// export const setUserType = userType => {
//   localStorage.setItem('user_type', userType);
// };
// export const getUserType = () => {
//   return localStorage.getItem('user_type');
// };
// export const clearUserType = () => {
//   localStorage.removeItem('user_type');
// };

// get user Types
export const getuserType = () => {
  const user = getUserInfo();
  if (user && Object.keys(user).length > 1) {
    return userTypes.registerUser;
  } else {
    return UserTypes.GUEST.toLowerCase();
  }
};

// get and set Ether/App wallet public key

export const addAppWalletPublicKey = (email, publicKey) => {
  let keys = {};
  keys = localStorage.getItem('walletPublicKeys') || {};

  try {
    keys = JSON.parse(keys);
    keys[email] = publicKey;
  } catch (e) {
    keys[email] = publicKey;
  }
  localStorage.setItem('walletPublicKeys', JSON.stringify(keys));
};

export const getAppWalletPublicKey = email => {
  let keys = localStorage.getItem('walletPublicKeys') || { [email]: null };
  try {
    keys = JSON.parse(keys);
  } catch (e) {
    keys[email] = null;
  }
  return keys[email];
};

// get and set Ether/App   wallet private key

export const addAppWalletPrivateKey = (email, privateKey) => {
  let keys = {};
  keys = localStorage.getItem('walletKeys') || {};

  try {
    keys = JSON.parse(keys);
    keys[email] = privateKey;
  } catch (e) {
    keys[email] = privateKey;
  }

  localStorage.setItem('walletKeys', JSON.stringify(keys));
};

export const getAppWalletPrivateKey = email => {
  let keys = localStorage.getItem('walletKeys') || { [email]: null };
  try {
    keys = JSON.parse(keys);
  } catch (e) {
    keys[email] = null;
  }
  return keys[email];
};

// get and set Tezos wallet public key

export const addTzsWalletPublicKey = (email, publicKey) => {
  let keys = {};
  keys = localStorage.getItem('tzs_public_keys') || {};

  try {
    keys = JSON.parse(keys);
    keys[email] = publicKey;
  } catch (e) {
    keys[email] = publicKey;
  }

  localStorage.setItem('tzs_public_keys', JSON.stringify(keys));
};

export const getTzsWalletPublicKey = email => {
  let keys = localStorage.getItem('tzs_public_keys') || { [email]: null };
  try {
    keys = JSON.parse(keys);
  } catch (e) {
    keys[email] = null;
  }
  return keys[email];
};

// get and set Tezos wallet private key

export function addTzsWalletPrivateKey(email, privateKey) {
  let keys = {};
  keys = localStorage.getItem('tzs_private_keys') || {};

  try {
    keys = JSON.parse(keys);
    keys[email] = privateKey;
  } catch (e) {
    keys[email] = privateKey;
  }

  localStorage.setItem('tzs_private_keys', JSON.stringify(keys));
}

export function getTzsWalletPrivateKey(email) {
  let keys = localStorage.getItem('tzs_private_keys') || { [email]: null };
  try {
    keys = JSON.parse(keys);
  } catch (e) {
    keys[email] = null;
  }
  return keys[email];
}

// get and set filters Data

export const setFiltersData = filtersData => {
  filtersData = JSON.stringify(filtersData);
  localStorage.setItem('filters', filtersData);
};

export const getFiltersData = () => {
  const filtersData = localStorage.getItem('filters');
  return JSON.parse(filtersData);
};

// get and set blockchainList data

export const setBlockchainList = blockchainList => {
  blockchainList = JSON.stringify(blockchainList);
  localStorage.setItem('blockchains', blockchainList);
};

export const getBlockchainList = () => {
  const blockchainData = localStorage.getItem('blockchains');
  return JSON.parse(blockchainData);
};

// update email for wallet keys

export const updateEmailforWalletKeys = email => {
  const oldEmail = getUserInfo().email;
  const newEmail = email;
  let existingPubKeys = window.localStorage.getItem('walletPublicKeys')?.replace(oldEmail, newEmail);
  let existingPvtKeys = window.localStorage.getItem('walletKeys')?.replace(oldEmail, newEmail);
  let existingTZSPubKeys = window.localStorage.getItem('tzs_public_keys')?.replace(oldEmail, newEmail);
  let existingTZSPvtKeys = window.localStorage.getItem('tzs_private_keys')?.replace(oldEmail, newEmail);
  existingPubKeys && window.localStorage.setItem('walletPublicKeys', existingPubKeys);
  existingPvtKeys && window.localStorage.setItem('walletKeys', existingPvtKeys);
  existingTZSPubKeys && window.localStorage.setItem('tzs_public_keys', existingTZSPubKeys);
  existingTZSPvtKeys && window.localStorage.setItem('tzs_private_keys', existingTZSPvtKeys);
};

/// get and set nftFav for guest user

export const getNftFav = () => {
  let nftfv = {};
  nftfv = localStorage.getItem('nftfv');
  nftfv = JSON.parse(nftfv);
  return nftfv || {};
};

export const setNftFav = (id, status) => {
  let list = getNftFav();
  list[id] = status;

  localStorage.setItem('nftfv', JSON.stringify(list));
};

export const clearNftFav = () => {
  localStorage.clear('nftfv');
};

/// cache tags, setting, locales

/// get ,set tags
export const setTagsInCache = tags => {
  localStorage.setItem('tags', JSON.stringify(tags));
};

export const gettagsFromCache = () => {
  return JSON.parse(localStorage.getItem('tags'));
};

// get,set settings
export const setSettingInCache = settings => {
  localStorage.setItem('settings', JSON.stringify(settings));
};

export const getSettingFromCache = () => {
  return JSON.parse(localStorage.getItem('settings'));
};

/// get,set dedault fillters data

export const setFilterDataInCache = filters => {
  localStorage.setItem('filters', JSON.stringify(filters));
};

export const getFiltersDataFromCache = () => {
  return JSON.parse(localStorage.getItem('filters'));
};

/// get,set blockchain types

export const setBlockchainTypesInCache = filters => {
  localStorage.setItem('blockchains', JSON.stringify(filters));
};

export const getBlockchainTypesFromCache = () => {
  return JSON.parse(localStorage.getItem('blockchains'));
};

export const setCacheTime = itemName => {
  let utcCurrent = moment.utc();
  let cache = {};
  cache = JSON.parse(localStorage.getItem('cache')) || {};
  cache[itemName] = utcCurrent;
  localStorage.setItem('cache', JSON.stringify(cache));
};

import React from 'react';
import { useSelector } from 'react-redux';
import { getLogoGif } from '../../utils/LogoIcons';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const Loader = () => {
  const loader = useSelector(state => state.loader.loading);

  return (
    loader && (
      <Modal open={loader} onClose={null} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box className='loader'>
          <img src={getLogoGif()} alt='4art-loader' height='150px' width='120px' />
        </Box>
      </Modal>
    )
  );
};

export default Loader;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import resources from './assets/locales.json';
import Backend from 'i18next-http-backend';
import localeApi from './services/locale/localeApi';
import { getLocale } from './services/helpers/token';
import enLocale from '../src/translations/en';
import deLocale from '../src/translations/de';

const initialzieLocale = langData => {
  let data = null;
  switch (getLocale()) {
    case 'en':
      data = { ...enLocale, ...langData };
      break;
    case 'de':
      data = { ...deLocale, ...langData };
      break;
    default:
      data = { ...enLocale, ...langData };
  }
  return data;
};

const backendOptions = {
  request: async (options, url, payload, callback) => {
    try {
      const response = await localeApi.getAppLocales(getLocale());
      if (response.data?.data) {
        const data = initialzieLocale(response.data.data);
        callback(null, {
          data,
          status: 200,
        });
      } else {
        const data = initialzieLocale({});
        callback(null, {
          data,
          status: 200,
        });
      }
    } catch (err) {
      callback(err);
    }
  },
};

i18n
  .use(Backend)
  // .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    supportedLngs: ['en', 'de'],
    backend: backendOptions,
    // resources,
    // lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    // fallbackLng: 'en',
    detection: {
      order: ['cookie', 'htmlTag', 'querystring', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain'],
      caches: ['cookie'],
    },
  });

export default i18n;

import fourArtInstance from '../fourArtInstance';
import { getToken } from '../helpers/token';
import { getIdNowUrl, getIdNowCustomerId, GET_MARKETPLACE_IDENTIFIER } from '../../helper/envVariables/envVariables';
import axios from 'axios';
import marketPlaceInstance from '../marketPlaceInstance';
import app from '../../app.json';

const appVersion = 2.4;

const config2 = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const idnowVerficationConfig = {
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Access-Control-Allow-Origin': '*',
  },
};
const loginUser = (email, password) => {
  const loginUserURI = `/api/v1/user-login?app_version=${appVersion}`;
  const data = new FormData();
  data.append('email', email);
  data.append('plainPassword', password);
  data.append('market_place_identifier', GET_MARKETPLACE_IDENTIFIER() || app.marketplace_identifier);

  return fourArtInstance.post(loginUserURI, data);
};

const registerUser = (company, userName, email, password, termAndCondions, privacyPolicy) => {
  const registerUserUrl = '/api/v1/user-register';
  const data = new FormData();
  data.append('company', company);
  data.append('name', userName);
  data.append('email', email);
  data.append('plainPassword', password);
  data.append('terms_and_conditions', termAndCondions);
  data.append('privacy_policy', privacyPolicy);
  data.append('app_version', appVersion);
  data.append('market_place_identifier', GET_MARKETPLACE_IDENTIFIER() || app.marketplace_identifier);

  return fourArtInstance.post(registerUserUrl, data);
};

const forgotPassword = (email, app) => {
  const forgetPasswordUrl = '/api/v1/user-setting-forgot-password';
  const data = new FormData();
  data.append('email', email);
  data.append('app', app);
  data.append('app_version', appVersion);

  return fourArtInstance.post(forgetPasswordUrl, data);
};

const updateUser = (street, zip, city, country, countryCode, vatNumber, userId, companyId, firstName, lastName, identId) => {
  const updateUserUri = `/api/v1/user/update-profile`;
  const data = new FormData();
  data.append('street', street);
  data.append('zip', zip);
  data.append('city', city);
  data.append('country', country);
  data.append('country_code', countryCode);
  data.append('ust_no', vatNumber);
  data.append('user_id', userId);
  data.append('first_name', firstName);
  data.append('last_name', lastName);
  data.append('company_id', companyId);
  if (identId) {
    data.append('ident_id', identId);
  }
  data.append('app_version', appVersion);

  return fourArtInstance.post(updateUserUri, data);
};

const changePassword = (password, newPassword) => {
  const url = '/api/v1/user-setting-change-password';

  const data = new FormData();
  data.append('plainPassword', password);
  data.append('newPlainPassword', newPassword);

  return fourArtInstance.post(url, data);
};

const resetPassword = (password, Apitoken) => {
  const token = getToken();

  const url = `/api/v1/user-setting-update-password-with-token`;

  const data = new FormData();
  data.append('plainPassword', password);
  data.append('app_version', appVersion);

  return fourArtInstance.post(url, data, {
    headers: {
      Authorization: token,
      resetPasswordToken: Apitoken,
    },
  });
};

const changeEmail = (email, app = 'marketplace_mobile_app') => {
  const url = 'api/v1/user/change-email';

  const data = new FormData();
  data.append('app', app);
  data.append('email', email);

  return fourArtInstance.post(url, data);
};

const changeEmailVerification = code => {
  const url = '/api/v1/user/change-email-code';

  const data = new FormData();
  data.append('code', code);

  return fourArtInstance.post(url, data);
};

const getUser = () => {
  const url = '/api/v1/user-setting-get-user-data';

  return fourArtInstance.get(url);
};

const createIdNow = (userId, companyId) => {
  const oS = 'react';
  const idNowUrl = `/api/v2/id-now/create`;
  const data = new FormData();
  data.append('user_id', userId);
  data.append('company_id', companyId);
  data.append('os', oS);

  return fourArtInstance.post(idNowUrl, data);
};

const uploadIdImages = (fontImg, backImg) => {
  const url = '/api/v1/user-register-upload-documents';

  const data = new FormData();
  data.append('imageFrontSideReference', fontImg);
  data.append('imageBackSideReference', backImg);

  return fourArtInstance.post(url, data, config2);
};

const updateIdNowStatus = identId => {
  const url = `/api/v2/id-now/update/status`;
  let data = new FormData();

  data.append('ident_id', identId);

  return fourArtInstance.post(url, data);
};

const startIdentification = identId => {
  const url = `${getIdNowUrl()}/api/v1/${getIdNowCustomerId()}/identifications/${identId}/start`;
  // return axios.post(startIdentificationUrl, {}, idnowVerficationConfig);

  return axios.post(url, idnowVerficationConfig);
};

const updateUserPayPalEmail = email => {
  const url = '/api/v1/update-paypal-email';

  const qParams = { paypal_email: email };

  return marketPlaceInstance.post(url, qParams);
};

const addDeviceToken = deviceToken => {
  const url = '/api/v1/user/device-token/add';

  const data = new FormData();
  data.append('token', deviceToken);

  return fourArtInstance.post(url, data);
};

const deleteUser = (app = 'marketplace_web_app') => {
  const deletuserUrl = '/api/v2/user-delete';

  const data = new FormData();

  data.append('app', app);

  return fourArtInstance.post(deletuserUrl, data);
};

export default {
  loginUser,
  registerUser,
  forgotPassword,
  updateUser,
  changePassword,
  getUser,
  createIdNow,
  uploadIdImages,
  updateIdNowStatus,
  startIdentification,
  resetPassword,
  changeEmail,
  changeEmailVerification,
  updateUserPayPalEmail,
  addDeviceToken,
  deleteUser,
};

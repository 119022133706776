export const de = {
  go_to_wallet_popup_msg_transfer:
    'Sie haben nicht genug %@ in Ihrem Wallet, um einen Transfer von %@ %@ durchzuführen. Bitte transferieren Sie min. %@ %@ in Ihr Wallet',
  go_to_wallet_popup_msg:
    'Sie haben nicht genug %@ in Ihrem Wallet, um einen Transfer von %@ %@ durchzuführen. Bitte transferieren Sie min. %@ %@ in Ihr Wallet',
  cancel: 'Abbrechen',
  confirm: 'Fortfahren',
  transfer_coin_popup_confirmation: "Sind Sie sicher, dass Sie %@ %@ für '%@' an %@ übertragen möchten?",
  home: 'Home',
  my_profile: 'Mein profil',
  notification: 'Mitteilungen',
  my_biddings: 'Meine gebote',
  my_offers: 'Meine Angebote',
  my_art: 'Meine kunst',
  support_and_faqs: 'Support und FAQs',
  legal: 'Rechtliches',
  logout: 'Ausloggen',
  support_and_faq_message: 'Bei Fragen können Sie jeder Zeit unseren Kundensuppport kontaktieren',
  app_online_guide: '4ARTapp ONLINE ANLEITUNG',
  contact_company: 'Kontakt 4ARTechnologies',
  '4art_address': '4ARTechnologies AG, Turmstr. 30, CH-6312 Steinhausen',
  supported_devices: 'Unterstützte Geräte',
  thankyou_message:
    'Danke, dass Sie die 4ARTapp benutzen. Gefällt Sie ihnen? Sie können uns jederzeit ein Feedback senden, indem Sie den Button unten nutzen.',
  send_feedback: 'Feedback senden',
  login: 'Einloggen',
  forgot_password: 'Passwort vergessen',
  register: 'Registrieren',
  email: 'E-Mail',
  country: 'Land',
  password: 'Passwort',
  no_internet_connection: 'Keine Internetverbindung',
  company_gallery_collection: 'Firma / Galerie / Sammlung',
  first_name: 'Vorname',
  last_name: 'Nachname',
  read_terms_and_conditions: 'Ich habe die AGB gelesen und stimme diesen zu',
  read_privacy_policy: 'Ich habe die Datenschutzerklärung gelesen und stimme dieser zu',
  empty_password_error: 'Aktuelles Passwort darf nicht leer sein',
  empty_email_error: 'E-Mail darf nicht leer sein',
  empty_value_error: 'Dieses Feld darf nicht leer sein.',
  invalid: 'ungültig',
  invalid_email: 'Die angegebene E-Mail-Adresse ist ungültig',
  password_length_error: 'Das angegebene Passwort ist zu kurz. Bitte wählen Sie eines mit mindestens 6 Zeichen aus',
  empty_first_name_error: 'Der Vorname darf nicht leer sein',
  empty_last_name_error: 'Der Nachname darf nicht leer sein',
  required: 'Erforderlich',
  edit_profile: 'Profil bearbeiten',
  marketplace_balance: 'Marktplatz guthaben',
  qr_code: 'QR-Code für Schlüsselübertragung',
  import_wallet_key: 'QR-Code für bestehende Wallet-keys scannen',
  import_wallet_key_msg:
    'Um Ihr bestehendes Application-Wallet nutzen zu können, öffnen sie bitte in Ihrem Profil in der 4ARTapp den Punkt „EXPORT WALLET KEYS” und scannen den unten angezeigten QR-Code.',
  personal_data: 'Persönliche Daten',
  fill_all_fields: 'Bitte füllen Sie alle Felder aus',
  change_password: 'Passwort ändern',
  street_house_number: 'Straße / Hausnummer',
  zip: 'PLZ',
  city: 'Ort',
  vat_number: 'VAT Nummer',
  success_user_updated: 'Erfolg! Benutzer erfolgreich aktualisiert',
  actual_password: 'Aktuelles passwort',
  new_password: 'Neues passwort',
  repeat_new_password: 'Neues Passwort wiederholen',
  ethereum: 'Ethereum',
  tezos: 'Tezos',
  show_keys: 'Keys anzeigen',
  hide_keys: 'Keys verstecken',
  public_key: 'Public Key',
  private_key: 'Private Key',
  key_copied: 'Schlüssel kopiert',
  recharge: 'Aufladen',
  transfer: 'Transfer',
  registraion_completed_msg:
    'Wir haben Ihnen gerade eine Bestätigungsmail zugesendet. Bitte bestätigen Sie Ihre Anmeldung durch Klick auf den in der Bestätigungsmail angegebenen Link, um die Registrierung abzuschließen.',
  terms_and_conditions: 'AGB',
  privacy_policy: 'Datenschutz',
  imprint: 'Impressum',
  start_user_identifications: 'Nutzer-Identifikation starten',
  market_place_listing_title: '4ART NFT Marktplatz',
  wallet_info_text_web:
    'Auf dem nächsten Bildschirm wird eine Brieftasche für Sie erstellt.\nBewahren Sie Ihre Brieftaschenschlüssel an einem sicheren Ort auf.',
  artist: 'Künstler*in',
  blockchain: 'Blockchain',
  price: 'Preis',
  sold: 'Verkauft',
  place_new_bid: 'Neues gebot',
  place_bid: 'Gebot abgeben',
  bids_placed: 'Gebote abgegeben',
  new: 'Neu',
  status: 'Status',
  year: 'Jahr',
  artist_royalties: 'Künstler royalties',
  address: 'Adresse',
  transfer_token_confirmation_msg:
    'Bitte überprüfen Sie noch einmal, ob der von Ihnen eingegebene Public Key korrekt ist. Beachten Sie, dass für den Transfer Gas Kosten von %@ anfallen. Der Betrag, der auf Ihrem Wallet gutgeschrieben wird, reduziert sich um diesen Betrag.',
  install_metamask: 'Bitte installieren Sie die MetaMask-Browsererweiterung',
  no_metamask_or_connection_error: 'Metamask Erweiterung wurde nicht gefunden, oder es gibt ein Verbindungsproblem',
  insufficient_ethers_balance: 'Zu geringes Ether Guthaben',
  error_fetching_ethers_balance: 'Felher beim Abrufen des ether Guthabens',
  insufficient_tokens_coins_balance: 'Zu geringes token-/coin-Guthaben',
  error_fetching_tokens_coins_balance: 'Fehler beim Abruf des token-/coin-Guthabens',
  error_fetching_estimates: 'Fehler beim Abruf der Schätzung',
  transfer_canceled: 'Transfer abgebrochen',
  all_transactions_completed_successfully: 'Alle Transaktionen wurden erfolgreich abgeschlossen',
  transaction_failed: 'Transaktion fehlgeschlagen',
  transferring_related_errors_occurred: 'Übertragungsfehler aufgetreten',
  an_unknown_error_occurred: 'Leider kann Ihre Anfrage gerade nicht verarbeitet werden. Bitte versuchen Sie es noch einmal!',
  success: 'Erfolg',
  ok: 'Ok',
  error: 'Fehler',
  wallet_used_for_payment_nft_in_app_wallet:
    'Bitte beachten Sie, dass %@ nur für die Zahlung verwendet werden kann. Die NFT+ werden auf Ihr 4ART-Applikations-Wallet transferiert',
  note: 'Hinweis',
  confirmation: 'Bestätigung',
  read: 'Gelesen',
  old: 'Alt',
  mark_as_read: 'Als gelesen markieren',
  see_inquiry: 'Anfrage Ansehen',
  transfer_now: 'Jetzt bezahlen',
  kyc_not_confirmed: 'Ihr Ausweisdokument wurde noch nicht bestätigt.',
  kyc_may_take_hours: 'Bitte beachten Sie, dass der Ausweis-Check bis zu 24 Stunden dauern kann.',
  complete_60_mins: 'Normalerweise wird er innerhalb von 60 Minuten abgeschlossen.',
  select_artwork_to_see_details: 'Wählen Sie ein Kunstwerk aus, um Details anzuzeigen.',
  connection_error: 'Verbindungsfehler',
  min_six_char: 'Mindestens sechs Zeichen',
  allowed_cahr_set_special_characters: 'Sonderzeichen (@,$,%,^,*,.....)',
  allowed_cahr_set_numbers: 'Zahlen (0,1,2,3...9)',
  allowed_cahr_set_capital_alphabets: 'Großbuchstaben (A-Z)',
  reset_password_title: 'Passwort zurückgesetzt',
  reset_password_instruction:
    'Wir haben Ihnen gerade eine E-Mail zugesendet. Die E-Mail enthält einen Link, über welchen Sie Ihr Passwort zurücksetzen können',
  personal_data_message: 'Hier können Sie Ihre persönlichen Daten einsehen und bearbeiten',
  save: 'Speichern',
  change_email: 'E-Mail ändern',
  password_match: 'Passwort passen',
  confirm_password: 'Passwort bestätigen',
  select_wallet: 'Wallet auswählen',
  qr: 'QR Code',
  qr_code_subtitle: 'QR-Code Ihres Private-Key und Public-Key',
  encrypted: 'Verschlüsselt',
  decrypted: 'Entschlüsselt',
  transfer_coins: 'Guthaben übertragen',
  transfer_coins_msg:
    'Bitte tragen Sie dafür Sorge, dass genügend Ether in Ihrem Wallet sind, um die Transferkosten (z.B. Gas Kosten) bezahlen zu können.',
  fill_balance: 'Aufladen',
  buy_credit: 'Mit Application Credits',
  buy_4art_coins_with_credits: 'Kaufen Sie 4ART Coins mit credits',
  buy_4art_coins_with_credits_msg: 'Wie viele 4ART Coins möchten Sie kaufen?',
  actual_balance: 'Aktuelles Guthaben',
  nft_type: 'NFT Typ',
  filter_title: 'Filter',
  filter_msg: 'Filtern Sie Ihre Nfts nach einem oder mehreren Kriterien.',
  search_term: 'Suchbegriff',
  us_dollars: 'US Dollars',
  apply_filter: 'Filter anwenden',
  code_sent_info:
    'Wir haben Ihnen einen Code an Ihre neue E-Mail Adresse gesendet. Bitte geben Sie den Code ein, um die Änderung zu bestätigen. Danach müssen Sie sich neu einloggen',
  code: 'Code',
  amount: 'Betrag',
  'Caution!': 'Achtung!',
  caution_message: 'Teilen Sie diesen QR-Code nie mit anderen Personen',
  accept_bid_popup: "Möchten Sie das Gebot von %@ %@ für '%@' annehmen?",
  your_paypal_account: 'Your PayPal account',
  paypal_email: 'PayPal E-Mail-Adresse',
  bid_has_been_accepted: 'Gebot wurde akzeptiert',
  email_field_required: 'Email field required',
  paypal_email_not_setup_please_authorize:
    'Ihr PayPal Konto ist noch nicht verifiziert. Bitte nutzen Sie "Login mit PayPal", um ihn jetzt zu verifizieren',
  loading_paypal_sdk: 'PayPal wird geladen...',
  failed_to_update_user_paypal_email:
    'Leider war es nicht möglich, Ihre Paypal E-Mail-Adresse zu aktualisieren. Bitte versuchen Sie es erneut',
  login_with_paypal_email_validation_success:
    'Vielen Dank, dass Sie Ihr Paypal Konto verifiziert haben. Bitte beachten Sie, dass das Gebot noch nicht von Ihnen angenommen wurde. Um dies zu tun, müssen Sie den Button nochmals anklicken. Möchten Sie fortfahren?',
  redirecting_to_offers_page: 'Redirecting to NFT offers page...',
  no_transactions_to_process: 'Es gibt keine Transaktion, die verarbeitet werden kann!',
  paypal_canceled_first_transaction_toast:
    'Beim Bezahlvorgang für "%@" ist entweder ein Fehler aufgetreten, oder Sie haben ihn abgebrochen. Bitte starten Sie den Vorgang erneut und überweisen den ausstehenden Betrag von "%@"USD',
  paypal_canceled_second_transaction_toast:
    'Der Bezahlvorgang für "%@" ist noch nicht abgeschlossen. Bitte führen Sie den Vorgang fort und überweisen den ausstehenden Betrag von "%@" USD',
  payment_processing_via_paypal: 'Bezahlung per PayPal wird verarbeitet',
  failed_to_load_paypal_sdk: 'PayPal konnte nicht geladen werden. Bitte versuchen Sie es noch einmal!',
  processing_transactions: 'Transaktionen werden verarbeitet',
  current_transaction: 'Derzeitige transaktion',
  paying_amount_to_receiver_msg: '%@ werden an %@ gezahlt',
  are_you_sure_msg_paypal:
    'Der Bezahlvorgang ist in zwei Transaktionen geteilt. Bei der ersten werden%@ USD an %@ überwiesen. Bei der zweiten Transaktion wird die Provision von %@ USD an %@ überwiesen',
  '4art_commission': '4ART kommission',
  royalty: 'Royalty',
  owner_amount: 'Benutzer betrag',
  accept_artwork_confirmation_msg: 'Sind Sie sicher, dass Sie die Anfrage annehmen möchten?',
  reject_artwork_confirmation_msg: 'Sind Sie sicher, dass Sie die Anfrage ablehnen möchten?',
  remove: 'Entfernen',
  accept: 'Annehmen',
  reject: 'Ablehnen',
  chat: 'Chat',
  conversations: 'Unterhaltungen',
  tezos_wallet_private_keys_not_found: 'Der Tezos Wallet Private Key wurde nicht gefunden',
  tezos_wallet_public_keys_not_found: 'Der Tezos Wallet Public Key wurde nicht gefunden',
  ethereum_wallet_private_keys_not_found: 'Der Ethereum Wallet Private Key wurde nicht gefunden',
  ethereum_wallet_public_keys_not_found: 'Der Ethereum Wallet Public Key wurde nicht gefunden',
  user_email_not_found: 'Benutzer E-Mail-Adresse nicht gefunden',
  bid_has_been_placed: 'Gebot wurde abgegeben',
  remove_bid_message: 'Sind Sie sicher, dass Sie Ihr Gebot zurückziehen möchten?',
  no: 'Nein',
  wallet_header: 'Ether Wallet',
  tezos_wallet: 'Tezos Wallet',
  title: 'Titel',
  material: 'Zeichenmaterial',
  base_material: 'Trägermaterial',
  height: 'Höhe',
  width: 'Breite',
  depth: 'Tiefe',
  resolution: 'Auflösung',
  format: 'Format',
  no_more_records: 'Keine Aufzeichnungen mehr',
  loading: 'Wird geladen',
  pay_now: 'Jetzt zahlen',
  copied_into_clipboard: 'In die Zwischenablage kopiert',
  buy_credits_with_coin: '%@ 4ARTCOINS jetzt kaufen',
  minimum_amount_200_credits: 'Mindestbetrag 200 Credtis',
  push_to_marketplace: 'Im Marktplatz anbieten',
  push_to_marketplace_message: 'Möchten Sie diesen NFT im Marktplatz anbieten?',
  services: 'Services',
  nft_image_selection: 'NFT Bildauswahl',
  image_selection: 'Bitte wählen Sie ein Bild für die Anzeige des Banners und des Thumbnails im Marktplatz aus.',
  image_size: 'Es sollte mindestens 600x600 Pixel haben, um vernünftig dargestellt zu werden.',
  continue: 'Weiter',
  set_price: 'Preis festlegen',
  must_be_number: 'Bitte geben Sie den Betrag in Zahl ein',
  price_must_be_postive: 'Der Preis muss eine positive Zahl sein',
  max_x_characters_allow: 'Maximal %@ Zeichen erlaubt',
  description: 'Beschreibung',
  write_the_description: 'Schreibe die Beschreibung',
  sort_by: 'Sortiert nach',
  random: 'Zufällig',
  hot: 'Hot',
  place_nft_in_marketplace: 'NFT im Marktplatz anbieten',
  next: 'Nächste',
  previous: 'Früher',
  page_x_of_y: 'Seite %@ von %@',
  logout_confirmation_msg: 'Sind Sie sicher, dass Sie sich abmelden möchten?',
  yes: 'Ja',
  remove_from_marketplace: 'Vom Marktplatz entfernen',
  remove_from_marketplace_message: 'Möchten Sie diesen NFT vom Marktplatz entfernen?',
  guest_user_info_title: 'Danke für Ihr Interesse',
  guest_user_info_description:
    'Um unsere Kunstwerk Dienste nutzen zu können, melden Sie sich bitte mit einem Benutzerkonto an oder erstellen Sie einen neuen Benutzer',
  dismiss: 'Schließen',
  choose_artwork: 'Wählen Sie Kunstwerk',
  copied: 'Kopiert',
  unknown: 'Unbekannt',
  description_in_en: 'BESCHREIBUNG IN ENGLISCH (INTERNATIONAL)',
  description_in_de: 'BESCHREIBUNG IN DEUTSCH',
  description_in_es: 'Beschreibung in Spanisch',
  description_in_fr: 'Beschreibung in Französisch',
  description_in_ru: 'Beschreibung in Russisch',
  description_in_ar: 'Beschreibung in Arabisch',
  description_in_it: 'Beschreibung in Italienisch',
  description_in_zh: 'Beschreibung in Chinesisch',
  description_in_ja: 'Beschreibung in Japanisch',
  description_in_pt: 'Beschreibung in Portugiesisch',
  overview: 'Überblick',
  documents: 'Dokumente',
  provenance: 'Provenienz',
  track: 'Track',
  request: 'Anfrage',
  requested: 'Angefragt',
  user_identification_header: 'Nutzer Identifikation',
  user_identification_title:
    'Da wir Kunstwerke unwiderruflich registrieren, müssen wir Ihre Identität sicherstellen. Dafür benötigen wir die Vorder- und Rückseite Ihres Ausweises. Alle Daten werden vertraulich behandelt und mit hoher Verschlüsselung verarbeitet.',
  information: 'Information',
  save_wallet: 'Wallet speichern',
  thank_registration: 'Vielen Dank für Ihre Registrierung',
  empty_field_error: 'Dieses Feld darf nicht leer sein',
  wallet_not_available:
    'Es scheint, dass Sie auf diesem Gerät zum ersten mal eingeloggt haben. Möchten Sie Ihren Private Key des Wallets via den QR-Code scannen?',
  wrong_login_attempts_message: 'Der Login ist für %@ Minute(n) aufgrund mehrfacher Falscheingaben nicht möglich',
  import_keys_manually: 'Schlüssel manuell importieren',
  offer_detail: 'Detail',
  bidding: 'Gebot',
  accepted: 'Akzeptiert',
  go_to_wallet: 'Zum Wallet',
  wallet_type: 'Wallet typ',
  delete_account_btn_text: 'Konto löschen',
  delete_account_confirmation_text: 'Möchten Sie Ihr 4ART-Konto wirklich löschen?',
  warning: 'Warnung',
  private_key_successfully_imported: 'Private Key wurde erfolgreich importiert',
  wallet_not_found: 'Das Wallet wurde nicht gefunden. Bitte erstellen Sie eines und fahren im Anschluss fort.',
  transactions_status: 'Transaktionen status',
  import_wallet_keys: 'Wallet-Schlüssel importieren',
  import_wallet_keys_from_app: 'Wallet Keys aus 4ARTapp importieren',
  enter_wallet_keys_manually: 'Wallet Keys eingeben',
  enter_wallet_keys_manually_msg:
    'In order to be able to use your existing wallet in the web marketplace, please enter you wallet private key in field below',
  import_wallet_keys_from_webcam_qr: 'QR-Code der Wallet Keys scannen',
  import_wallet_keys_from_webcam_qr_msg: 'Bitte halten Sie den QR-Code Ihres Private Key vor Ihre Webcam',
  no_result: 'Kein Ergebnis',
  open_webcam: 'Offene Webcam',
  scan_private_key: 'Bitte scannen Sie den privaten Schlüssel Ihres Wallets',
  type_here: 'Hier eingeben...',
  select_payment_method_to_proceed: 'Wählen Sie die Zahlungsmethode aus, um fortzufahren',
  scanned_key_is_not_private_key: 'Der gescannte Schlüssel ist nicht privat',
  invalid_key_pair_public_key_not_matched:
    'Ungültiges Schlüsselpaar, öffentlicher Schlüssel stimmt nicht mit öffentlichem Schlüssel der Brieftasche überein',
  submit: 'einreichen',
  no_tezos_wallet_please_create: 'Anscheinend haben Sie keine Tezos-Wallet, bitte erstellen Sie eine.',
  create_wallet: 'Brieftasche erstellen',
  scan_keys: 'Schlüssel scannen',
  import: 'Import',
  send: 'Senden',
  payment_method: 'Zahlungsmittel',
  payment_methods: 'Zahlungsmethoden',
  price_per_token: 'Preis pro Token',
  must_be_postive: 'Bitte geben Sie ein positive Zahl ein',
  must_be_a_number: 'Bitte geben Sie nur Zahlen ein',
  tokens: 'Token',
  quantity: 'Anzahl',
  selling_token_message: 'Wiewiele Token möchten Sie auf demMarktplatz verkaufen?',
  available: 'Verfügbar',
  total_price_all_tokens: 'Gesamtpreis aller Token',
  n_a: 'n/a',
  total_price: 'Gesamtpreis',
  buy_now: 'Jetzt kaufen',
  currency_selection: 'Currency selection',
  nft_chunks_fetching_error: 'Fehler bei der Abfrage der Pakete',
  available_chunks: 'Verfügbare Pakete',
  no_chunks_found: 'Kein Paket gefunden',
  new_chunk: 'Neues Paket',
  chunk_data_error: 'Fehler in den Paketdaten',
  remove_multiple_token_msg: 'Alle Token werden vom Marktplatz entfernt. Möchten Sie fortfahren?',
  already_available_on_marketplace: 'Bereits auf dem Marktplatz verfügbar',
  archive_notification: 'Archivierte nitteilungen',
  activities: 'Activities',
  same_price_chunk_available:
    'Ein Paket mit dem selben Preis, den Sie gerade eingegeben haben, wird bereits auf dem Marktplatz angeboten.',
  merge_in_existing_chunk_confirmation_msg: 'Sind Sie sicher, dass Sie mit dem existierenden Paket zusammenführen möchten?',
  enter_bidding_amount: 'Bitte geben Sie den Betrag für Ihr Gebot ein',
  merge: 'ZUSAMMENFÜHREN',
  change_price: 'PREIS ÄNDERN',
  field_value_greater_than_or_equal: 'Bitte geben Sie einen Wert ein, der größer oder gleich %@ ist',
  field_value_less_than_or_equal: 'Bitte geben Sie einen Wert ein, der kleiner oder gleich %@ ist',
  forgot_password_title: 'Geben Sie bitte Ihre E-Mail Adresse an, um Ihr Passwort zurückzusetzen',
  wallet_address: 'Wallet Addresse',
  small_image_resolution_message: 'Die Auflösung des gewählten Bildes ist zu klein. Bitte wählen Sie ein anderes Bild aus',
  pending: 'Ausstehend',
  inprogress: 'In Bearbeitung',
  complete: 'Komplett',
};

export default de;

import { createSlice } from '@reduxjs/toolkit';

const nftSlice = createSlice({
  name: 'nfts',
  initialState: { nfts: [], nftsCount: 0 },
  reducers: {
    setNfts(state, action) {
      const allNfts = action.payload;
      state.nfts = allNfts;
    },
    addNfts(state, action) {
      const updatedData = action.payload;
      state.nfts.push(...updatedData);
    },
    setNftsCount(state, action) {
      const nftsCount = action.payload;
      state.nftsCount = nftsCount;
    },
    updateSingleNft(state, action) {
      const idToSearch = action.payload.nftId;
      const propsToUpdate = action.payload.properties;

      state.nfts.find((nft, index) => {
        if (nft.id === idToSearch) {
          const nftData = { ...state.nfts[index] };
          state.nfts[index] = { ...nftData, ...propsToUpdate };
          return true; // return from loop
        }
      });
    },
    removeSingleNft(state, action) {
      const idToSearch = action.payload;
      const filterData = state.nfts.filter(nft => nft.id !== idToSearch);
      state.nfts = [...filterData];
      state.nftsCount = filterData.length;
    },
  },
});

export const nftsActions = nftSlice.actions;

export default nftSlice;

import { createSlice } from '@reduxjs/toolkit';

const selectedArtworkSlice = createSlice({
  name: 'selectedArtwork',
  initialState: { selectedArtwork: null, artworkSelected: false },
  reducers: {
    setSelectedArtwork(state, action) {
      const selectedArtwork = action.payload;
      state.selectedArtwork = selectedArtwork;
    },
    resetSelectedArtwork(state) {
      state.selectedArtwork = null;
    },
    setArtworkSelected(state) {
      state.artworkSelected = true;
    },
    resetArtworkSelected(state) {
      state.artworkSelected = false;
    },
  },
});

export const selectedArtworkActions = selectedArtworkSlice.actions;

export default selectedArtworkSlice;

import React from 'react';
import PropTypes from 'prop-types';
import AlertBox from '../components/Models/AlertBox';
import { useTranslation } from 'react-i18next';

const ReactNotification = ({ open, title, body, onClose }) => {
  const { t } = useTranslation();
  return (
    <AlertBox
      open={open}
      title={title}
      body={body}
      // textForNo={t('no')}
      textForYes={t('ok')}
      // onClickNo={closeRemoveBidHandler}
      onClickYes={onClose}
    />
  );
};

ReactNotification.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  onClose: PropTypes.func,
};

export default ReactNotification;

import { createSlice } from '@reduxjs/toolkit';

const filters = {
  searchTerm: null,
  title: null,
  artist: null,
  yearFrom: null,
  yearTo: null,
  priceFrom: null,
  priceTo: null,
  type: null,
  blockchain: null,
  address: null,
  listingType: 'random',
  tagId: null,
  favourite: null,
  orderBy: null,
  sortedByField: null,
};
const filtersSlice = createSlice({
  name: 'filters',
  initialState: { filters: filters },
  reducers: {
    setFilters(state, action) {
      const filters = state.filters;
      const filterItem = action.payload;
      state.filters = { ...filters, ...filterItem };
    },
  },
});

export const filtersActions = filtersSlice.actions;

export default filtersSlice;

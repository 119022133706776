import moment from 'moment';

const storage = window.localStorage;

const getDataItem = (key, jsonParse = false) => {
  const data = storage.getItem(key);
  return jsonParse ? JSON.parse(data) : data;
};
const setDataItem = (key, data = '', jsonStringify = false) => storage.setItem(key, jsonStringify ? JSON.stringify(data) : data);
const delDataItem = key => storage.removeItem(key);

// User Info
const keyUserInfo = 'user';
const UserInfo = {
  get: (parseData = true) => getDataItem(keyUserInfo, parseData),
  set: (data, stringifyData = true) => setDataItem(keyUserInfo, data, stringifyData),
  del: () => delDataItem(keyUserInfo),
};

// Redirect URL User After Login with PayPal
const keyRedirectUrlAfterLoginWithPayPalSuccess = 'redirect-after-login-with-paypal-url';
const RedirectUrlAfterLoginWithPayPalSuccess = {
  get: (parseData = true) => getDataItem(keyRedirectUrlAfterLoginWithPayPalSuccess, parseData),
  set: (data, stringifyData = true) => setDataItem(keyRedirectUrlAfterLoginWithPayPalSuccess, data, stringifyData),
  del: () => delDataItem(keyRedirectUrlAfterLoginWithPayPalSuccess),
};

// Conversion Rates
const keyConversionRates = 'coversionRate';
const ConversionRates = {
  get: (parseData = true) => getDataItem(keyConversionRates, parseData),
  set: (data, stringifyData = true) => setDataItem(keyConversionRates, data, stringifyData),
  del: () => delDataItem(keyConversionRates),
};

// Cache Times
const keyLocalStorageCache = 'cache';
const LocalStorageCache = {
  get: (parseData = true) => getDataItem(keyLocalStorageCache, parseData),
  set: (name, stringifyData = true) => {
    const modData = { ...(LocalStorageCache.get() || {}), [name]: moment.utc() };
    return setDataItem(keyLocalStorageCache, modData, stringifyData);
  },
  del: () => delDataItem(keyLocalStorageCache),
};

export {
  LocalStorageCache,
  getDataItem,
  setDataItem,
  delDataItem,
  UserInfo,
  RedirectUrlAfterLoginWithPayPalSuccess,
  ConversionRates,
};

import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { getLogoGif } from '../../utils/LogoIcons';

const SpinnerLoading = props => {
  const styles = { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' };

  if (props.hideFullHeight) {
    Object.assign(styles, { height: '' });
  }

  return (
    <Box sx={styles}>
      <img src={getLogoGif()} alt='4art-loader' height='150px' width='120px' />
    </Box>
  );
};

SpinnerLoading.propTypes = {
  applyViewHeight: PropTypes.bool,
};

SpinnerLoading.defaultProps = {
  applyViewHeight: false,
};

export default SpinnerLoading;

import moment from 'moment';
import { LocalStorageCache } from './localStorage';

const isCacheExpired = (name, minutes /* in minutes */ = 5) => {
  // setting cache to 5 mins
  const cacheTimes = LocalStorageCache.get();

  if (!cacheTimes || !cacheTimes[name]) {
    return true;
  }

  const ctime = cacheTimes[name];

  return moment.utc().diff(moment(ctime).utc(), 'minutes') > minutes;
};

export { isCacheExpired };
